import {SVGProps} from "react";

function Send(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 -0.5 25 25">
            <path
                fill="inherit"
                d="M19.117 12.148a.75.75 0 10.759-1.293l-.76 1.293zM6.943 4.137l-.387.642.008.004.38-.646zm-1.019.023l-.416-.624.416.624zm-.414.932l-.742.11a.75.75 0 00.037.145l.705-.255zm1.614 6.665a.75.75 0 001.41-.51l-1.41.51zm12.752.391a.75.75 0 10-.76-1.293l.76 1.293zM6.943 18.867l-.38-.647-.007.004.387.643zm-1.019-.024l-.416.624.416-.624zm-.414-.931l-.705-.256a.75.75 0 00-.037.146l.742.11zm3.025-6.155a.75.75 0 00-1.41-.51l1.41.51zm10.961.495a.75.75 0 000-1.5v1.5zm-11.667-1.5a.75.75 0 100 1.5v-1.5zm12.047.103L7.323 3.49l-.76 1.293 12.554 7.365.759-1.293zM7.33 3.495a1.702 1.702 0 00-1.822.04l.832 1.249a.202.202 0 01.216-.005l.774-1.285zm-1.822.04a1.702 1.702 0 00-.74 1.667l1.484-.22a.202.202 0 01.088-.198l-.832-1.248zm-.703 1.812l2.32 6.41 1.41-.51-2.32-6.41-1.41.51zm14.312 5.508L6.564 18.22l.759 1.293 12.553-7.365-.76-1.293zm-12.56 7.37a.202.202 0 01-.217-.006l-.832 1.248a1.702 1.702 0 001.822.042l-.774-1.285zm-.217-.006a.202.202 0 01-.088-.198l-1.484-.22c-.096.652.192 1.301.74 1.666l.832-1.248zm-.124-.052l2.319-6.41-1.41-.51-2.32 6.41 1.41.51zm13.28-7.415H7.83v1.5h11.667v-1.5z"
            ></path>
        </svg>
    );
}

export default Send;