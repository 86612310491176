import {SVGProps} from "react";

function MinimizeIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="#000"
            version="1.1"
            viewBox="0 0 489.3 489.3"
            xmlSpace="preserve"
            {...props}
        >
            <path d="M0 12.251v464.7c0 6.8 5.5 12.3 12.3 12.3h224c6.8 0 12.3-5.5 12.3-12.3s-5.5-12.3-12.3-12.3H24.5v-440.2h440.2v210.5c0 6.8 5.5 12.2 12.3 12.2s12.3-5.5 12.3-12.2v-222.7c0-6.8-5.5-12.2-12.3-12.2H12.3C5.5-.049 0 5.451 0 12.251z"></path>
            <path d="M476.9 489.151c6.8 0 12.3-5.5 12.3-12.3v-170.3c0-6.8-5.5-12.3-12.3-12.3H306.6c-6.8 0-12.3 5.5-12.3 12.3v170.4c0 6.8 5.5 12.3 12.3 12.3h170.3v-.1zm-158.1-170.4h145.9v145.9H318.8v-145.9zM135.9 257.651c0 6.8 5.5 12.3 12.3 12.3h109.5c6.8 0 12.3-5.5 12.3-12.3v-109.5c0-6.8-5.5-12.3-12.3-12.3s-12.3 5.5-12.3 12.3v79.9l-138.7-138.7c-4.8-4.8-12.5-4.8-17.3 0-4.8 4.8-4.8 12.5 0 17.3l138.7 138.7h-79.9c-6.8 0-12.3 5.5-12.3 12.3z"></path>
        </svg>
    );
}

export default MinimizeIcon;